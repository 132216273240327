import { getTranslationData } from '~/lib/api';

type TranslationResolverProps = {
    url: string;
};

export async function translationResolver({ url }: TranslationResolverProps) {
    const data = await getTranslationData(url);

    return {
        data,
    };
}
