import { getFrameData } from '~/lib/api';

type FrameResolverProps = {
    url: string;
};

export async function frameResolver({ url }: FrameResolverProps) {
    const data = await getFrameData(url);

    return {
        data,
    };
}
