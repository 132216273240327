import React from 'react';
import dynamic from 'next/dynamic';
import { ComponentType, FunctionComponent } from 'react';
import { PageProvider } from '../../context/PageProvider';
import { DynamicPageProps } from '../DynamicPage';

export interface LayoutType {
    [key: string]: ComponentType;
}

const layouts: LayoutType = {
    standard: dynamic(() => import('./components/Standard/Standard')),
    checkout: dynamic(() => import('./components/Checkout/Checkout')),
};

const getLayout = (pageType: string) => {
    switch (pageType) {
        case 'p100CheckoutPage': {
            return layouts.checkout;
        }
        default:
            return layouts.standard;
    }
};

const DynamicLayout: FunctionComponent<{ pageProps: DynamicPageProps }> = ({
    children,
    pageProps,
}) => {
    const Layout = getLayout(pageProps?.page?.type as string);
    return (
        <PageProvider page={pageProps?.page}>
            <Layout>{children}</Layout>
        </PageProvider>
    );
};

export default DynamicLayout;
