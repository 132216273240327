import truncate from 'just-truncate';
import { ProductLabelSettings, UmbracoAlgoliaCategories } from '~/lib';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { TranslateFn } from '~/shared/utils/translation/hooks/useTranslation';
import { createNotifications } from '../product-details-page/pdp-utils';

export const _transformAlgoliaToProductDetails = (
    p: any,
    translate: TranslateFn,
    convertPrice: (price: number) => string,
    frameProductSettings?: ProductLabelSettings
): TProductDetails => {
    return {
        ...p,
        splash: p.cust_splash && { text: p.cust_splash, variant: 'campaign' },
        name: truncate(p.name, 26),
        longName: p.name,
        images: getImages(p.image_groups),
        ctaButtons: {
            primary: p?.cust_buyButtonsObj?.c_primaryButton,
            secondary: p?.cust_buyButtonsObj?.c_secondaryButton,
        },
        ctaButtonProductCard: (() => {
            try {
                if (!p?.cust_buyButtonsObj?.c_primaryButton?.isDisabled) {
                    return p?.cust_buyButtonsObj?.c_primaryButton;
                }

                if (p?.cust_buyButtonsObj?.c_secondaryButton?.isDisabled) {
                    return p?.cust_buyButtonsObj?.c_primaryButton;
                }

                return p?.cust_buyButtonsObj?.c_secondaryButton;
            } catch {
                return false;
            }
        })(),
        prices: p.cust_prices,
        cust_promotionId:
            p.cust_activepromo?.promotionid?.startsWith &&
            p.cust_activepromo?.promotionid?.startsWith('HOOF-')
                ? p.cust_activepromo.promotionid
                : undefined,
        brand: {
            text: p.brand,
        },
        infoTopRight: p.cust_sole_representation ? translate('pcard.soleRepresentation') : null,
        label: p.cust_synopsis ?? null,
        url: setSlashOnUrl(p.url),
        notifications: createNotifications(p, translate, convertPrice, frameProductSettings),
    };
};

type Image = {
    _type: 'image';
    alt: string;
    dis_base_link: string;
};
type ImageGroup = {
    _type: 'image_group';
    view_type: 'large';
    images: Image[];
};

const getImages = (imageGroups: ImageGroup[]) => {
    if (!imageGroups) return null;
    const images = imageGroups[0].images;
    return images.map((image) => ({ ...image, src: image.dis_base_link }));
};

const setSlashOnUrl = (url: string) => {
    if (!url.startsWith('/')) {
        return `/${url}`;
    }
    return url;
};

const showOnlyOnline = (filter: string) => (filter ? `${filter} AND online:true` : 'online:true');

const generateAlgoliaConfigPropsInternal = ({
    filter,
    filterType,
    productIds,
    campaign,
}: {
    filter: string;
    filterType?: keyof typeof UmbracoAlgoliaCategories;
    productIds?: string | string[];
    campaign?: string | string[];
}) => {
    if (!filterType) return {};

    if (typeof productIds === 'string') {
        const pidFilter = 'id:' + productIds.split(',').join(' OR id:');
        return { filters: showOnlyOnline(pidFilter) };
    }

    if (typeof campaign === 'string') {
        return {
            filters: showOnlyOnline('cust_activepromo.fulllist.promotionid:"' + campaign + '"'),
        };
    }

    if (filterType === 'Custom') {
        return { filters: showOnlyOnline(filter) };
    }

    const query = filter.split(',').join(' AND ');

    if (filterType === 'Context Rule Name') {
        return {
            filters: showOnlyOnline(''),
            ruleContexts: filter.split(','),
        };
    }
    const filters = `${UmbracoAlgoliaCategories[filterType]}: ${JSON.stringify(query)}`;

    return {
        filters: showOnlyOnline(filters),
        ruleContexts: [],
    };
};

export const generateAlgoliaConfigProps = ({
    filter,
    filterType,
    parentFilter,
    parentFilterType,
    productIds,
    campaign,
}: {
    filter: string;
    filterType?: keyof typeof UmbracoAlgoliaCategories;

    parentFilter?: string;
    parentFilterType?: keyof typeof UmbracoAlgoliaCategories;
    productIds?: string | string[];
    campaign?: string | string[];
}) => {
    const algoliaProps = generateAlgoliaConfigPropsInternal({
        filter: filter,
        filterType: filterType,
        productIds,
        campaign,
    });

    if (parentFilter && parentFilterType) {
        const parentAlgoliaProps = generateAlgoliaConfigPropsInternal({
            filter: parentFilter,
            filterType: parentFilterType,
        });
        algoliaProps.filters += ` AND ${parentAlgoliaProps.filters}`;
        if (parentAlgoliaProps.ruleContexts) {
            algoliaProps.ruleContexts = algoliaProps.ruleContexts?.concat(
                parentAlgoliaProps.ruleContexts
            );
        }
    }
    return algoliaProps;
};
