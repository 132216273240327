import { frameResolver } from './frameResolver';
import { translationResolver } from './translationResolver';

import { Storage as StorageType } from '~/features/commerce-api/auth';
import { Frame } from '~/lib';
import { Translations } from '~/shared/utils/translation';

type GetInitialLoadExtraContentProps = {
    url: string;
};

export const getInitialLoadExtraContent = async (
    storage: StorageType,
    _initialLoad: boolean,
    props: GetInitialLoadExtraContentProps
) => {
    let frame = JSON.parse(storage.get('frame')) as Frame;
    let translations = JSON.parse(storage.get('translations')) as Translations;

    const lastUpdated = storage.get('updated');
    const lastUpdatedDate = lastUpdated ? new Date(JSON.parse(lastUpdated)) : undefined;
    const needsUpdate = lastUpdatedDate
        ? (new Date().getTime() - lastUpdatedDate.getTime()) / (1000 * 60) > 5
        : true;

    if (needsUpdate || !lastUpdated) {
        const frameRequest = frameResolver(props);
        const translationRequest = translationResolver(props);

        console.time('frameResolver');
        const { data: frameData } = await frameRequest;
        frame = frameData;
        storage.set('frame', JSON.stringify(frame));
        console.timeEnd('frameResolver');

        console.time('translationResolver');
        const { data: translationsData } = await translationRequest;
        storage.set('translations', JSON.stringify(translations));
        translations = translationsData;
        console.timeEnd('translationResolver');

        storage.set('updated', JSON.stringify(new Date()));
    } else {
        //backup case in case somehow the frame/translation from storage is null
        if (!frame) {
            const { data: newFrameData } = await frameResolver(props);
            storage.set('frame', JSON.stringify(frame));
            frame = newFrameData;
        }
        if (!translations) {
            const { data: translationsData } = await translationResolver(props);
            storage.set('translations', JSON.stringify(translations));
            translations = translationsData;
        }
    }

    return {
        frame,
        translations,
    };
};
